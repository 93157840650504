<template>
  <div class="handleWrap">
    <div class="flex-col relative section-handle">
      <div class="flex-row justify-between items-center group">
        <div class="flex-row items-center space-x-124">
          <el-image class="shrink-0 image" :src="require('@/assets/image/logo.png')" @click="toRouter('/')"></el-image>
          <div class="group_2">
            <span :class="routeName == '' ? 'font_1 text border-b' : 'font_1 text'" @click="toRouter('/')">首页</span>
            <span :class="routeName == 'master' ? 'font_1 text border-b' : 'font_1 text'" @click="toRouter('/master')">师傅入驻</span>
            <span :class="routeName == 'agent' ? 'font_1 text border-b' : 'font_1 text'" @click="toRouter('/agent')">成为代理商</span>
          </div>
        </div>
        <div class="flex-row items-center space-x-80">
          <div class="flex-row items-center">
            <div style="margin-right:60px">
              <el-popover
                style="text-align:center;padding:12px;"
                placement="bottom"
                title=""
                trigger="click">
                <img style="width:150px;height:150px;" :src="require('@/assets/image/code-ios1.png')" />
                <img style="width:150px;height:150px;" :src="require('@/assets/image/code-android1.png')" />
                <div class="flex-row items-center space-x-11" slot="reference">
                  <span class="font_2 text_3">下载吾有空App</span>
                  <img
                    class="shrink-0 image_2"
                    :src="require('@/assets/image/dow.png')"
                  />
                </div>
              </el-popover>
            </div>

            <el-popover
              placement="bottom"
              trigger="click">
              <img style="width:150px;height:150px;display:block;" :src="require('@/assets/image/code.png')" />
              <div class="flex-row items-center space-x-11" slot="reference">
                <span class="font_2 text_4">微信小程序</span>
                <img
                  class="shrink-0 image_2"
                  :src="require('@/assets/image/dow.png')"
                />
              </div>
            </el-popover>
          </div>
          <div class="flex-col justify-start items-center text-wrapper flash-hover" @click="onLogo">立即登录</div>
         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  data() {
    return {
      tabIndex: 1,
      routeName: "",
    }
  },
  created() {
    this.routeName = this.$route.name;
    console.log(this.routeName)
  },
  methods: {
    clickPush(num, router) {
      this.tabIndex = num;
      this.$router.push(router)
    },
    toRouter(url) {
      window.open(url, '_self'); // _blank, _self
    },
    onLogo() {
      if(process.env.NODE_ENV == 'development') {
        window.open('http://lingong.job.cuntoubao.test', '_blank');
      } else if (process.env.NODE_ENV == 'production') {
        window.open('https://lingong.job.cuntoubao.cn', '_blank');
      }
    }
  }
}
</script>
<style scoped>
.handleWrap {
  width: 100%;
  background: #fff;
  z-index: 999;
  position: sticky;
  top: 0;
  box-shadow: 1px -10px 15px 0 rgb(29 123 252);
}
.section-handle {
  width: 1320px;
  margin: 0 auto;
  background-color: #ffffff;
}
.group {
  /* padding: 19px 0 15px; */
  height: 64px;
}
.space-x-124 > *:not(:first-child) {
  margin-left: 100px;
}
.image {
  width: 150px;
  height: 40px;
}
.group_2 {
  line-height: 20px;
  height: 20px;
}
.group_2 a {
  text-decoration: none;
}
.font_1 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  line-height: 20px;
  color: #323232;
}
.text {
  letter-spacing: 2px;
  height: 42px;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
}
.text:hover {
  /* color: #1d7bfc;
  border-bottom: solid 4px #1d7bfc; */
}
.space-x-80 > *:not(:first-child) {
  margin-left: 80px;
}
.space-x-60 > *:not(:first-child) {
  margin-left: 60px;
}
.space-x-11 > *:not(:first-child) {
  margin-left: 11px;
}
.font_2 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  color: #333333;
}
.text_3 {
  line-height: 19px;
  cursor: pointer;
}
.image_2 {
  width: 12px;
  height: 7px;
}
.text_4 {
  line-height: 17px;
  cursor: pointer;
}
.text-wrapper {
  padding: 12px 0;
  background-color: #1d7bfc;
  border-radius: 6px;
  width: 100px;
  height: 40px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  color: #ffffff;
  line-height: 16px;
  cursor: pointer;
}
.border-b {
  border-bottom: solid 4px #1d7bfc;
  color: #1d7bfc;
}
.handleWrap ::v-deep .el-popover {
  padding: 0 !important;
}
</style>
<style>
.el-popover {
  padding: 0 !important;
}
</style>